<template>
  <div data-cy="user-page">
    <div
      class="bg-image"
      :style="`
      background-image: url('https://images.unsplash.com/photo-1473448912268-2022ce9509d8?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1925&q=80');
    `"
    >
      <div class="bg-primary-dark-op">
        <div class="content content-full">
          <div class="py-5 text-center">
            <router-link class="img-link" to="/profile">
              <img class="img-avatar img-avatar96 img-avatar-thumb" :src="$auth.user.picture" alt />
            </router-link>
            <h1 data-cy="user-name" class="font-w700 my-2 text-white">{{ $auth.user.name }}</h1>
            <h2 data-cy="user-email" class="h4 font-w700 text-white-75">{{ $auth.user.email }}</h2>
          </div>
        </div>
      </div>
    </div>
    <div class="content content-full content-boxed">
      <div v-show="errorAction.updateUser" class="alert alert-danger">{{ errorAction.updateUser }}</div>
      <div v-if="!$auth.user.email_verified" class="alert alert-warning d-flex align-items-center">
        <div class="flex-00-auto">
          <i class="fa fa-fw fa-exclamation"></i>
        </div>
        <div class="flex-fill ml-3">
          <p class="mb-0">
            {{ $t('ACCOUNT_NOT_VERIFIED') }}
            <a class="alert-link" href="#">{{ $t('CLICK_HERE') }}</a> {{ $t('EMAIL_VERIFICATION') }}
          </p>
        </div>
      </div>
      <div class="block block-rounded">
        <div class="block-content">
          <form method="POST" enctype="multipart/form-data" @submit.prevent="onSubmit">
            <!-- User Profile -->
            <h2 class="content-heading pt-0"><i class="fa fa-fw fa-user-circle text-muted mr-1"></i> {{ $t('USER_PROFILE') }}</h2>
            <div class="row push">
              <div class="col-lg-4">
                <p class="text-muted">{{ $t('USER_PROFILE_INFO') }}</p>
              </div>
              <div class="col-lg-8 col-xl-5">
                <FormGroup id="name" v-model="form.name" data-cy="form-name" type="text" name="name" :label="$t('NAME')" />
              </div>
            </div>
            <h2 class="content-heading pt-0"><i class="fa fa-fw fa-asterisk text-muted mr-1"></i> {{ $t('CHANGE_PASSWORD') }}</h2>
            <div class="row push">
              <div class="col-lg-4">
                <p class="text-muted">{{ $t('CHANGE_PASSWORD_TEXT') }}</p>
              </div>
              <div class="col-lg-8 col-xl-5">
                <FormGroup
                  id="password"
                  v-model="form.password"
                  data-cy="form-password"
                  :error="validationErrors.password"
                  type="password"
                  :label="$t('NEW_PASSWORD')"
                />
                <FormGroup
                  id="password2"
                  v-model="form.password2"
                  data-cy="form-password2"
                  :error="validationErrors.password2"
                  type="password"
                  :label="$t('CONFIRM_NEW_PASSWORD')"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-lg-8 col-xl-5 offset-lg-4">
                <div class="form-group">
                  <button data-cy="form-submit" type="submit" class="btn btn-primary">
                    <i class="fa fa-check-circle mr-1"></i> {{ $t('UPDATE_PROFILE_BUTTON') }}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div v-if="$auth.roles.includes('public_api_user')" class="block block-rounded">
        <div class="block-content">
          <!-- User Profile -->
          <h2 class="content-heading pt-0"><i class="fa fa-fw fa-key text-muted mr-1"></i> {{ $t('API_ACCESS') }}</h2>
          <div class="row push">
            <div class="col-lg-4">
              <p class="text-muted">{{ $t('API_ACCESS_TEXT') }}</p>
              <p class="text-danger font-w600">{{ $t('API_ACCESS_WARNING') }}</p>
            </div>
            <div class="col-lg-10 col-xl-8">
              <p>
                For API documentation please check out:
                <a class="font-w600 text-primary" target="_blank" href="https://docs.etainabl.com">https://docs.etainabl.com</a>
              </p>
              <div v-if="user.apiKey" class="input-group mb-3">
                <FormItem
                  id="apiKey"
                  :value="apiKey"
                  :disabled="true"
                  placeholder="*************************************************************"
                  :label="$t('API Key')"
                />
                <div class="input-group-append">
                  <button :disabled="loading.usage || loading.newKey || loading.getKey" class="btn btn-alt-info mr-3" @click="onClickGetKey">
                    <span v-if="loading.getKey"><b-spinner class="mr-2" small></b-spinner>{{ $t('LOADING') }}</span
                    ><span v-else>{{ apiKey ? 'Hide' : 'View' }} API Key</span>
                  </button>
                </div>
              </div>

              <button
                v-if="user.apiKey"
                :disabled="loading.usage || loading.newKey || loading.getKey"
                class="btn btn-primary mr-3"
                @click="onClickViewUsage"
              >
                <span v-if="loading.usage"><b-spinner class="mr-2" small></b-spinner>{{ $t('LOADING') }}</span
                ><span v-else>{{ $t('VIEW_USAGE_BUTTON') }}</span>
              </button>

              <button :disabled="loading.usage || loading.newKey || loading.getKey" class="btn btn-alt-warning" @click="modals.confirm = true">
                <span v-if="loading.newKey"><b-spinner class="mr-2" small></b-spinner>{{ $t('LOADING') }}</span
                ><span v-else>{{ $t('GENERATE_NEW_KEY_BUTTON') }}</span>
              </button>

              <div v-if="apiUsage.retrieved" class="my-5">
                <p class="font-w600 mb-1">This month requests: {{ apiUsage.currentMonth }}</p>
                <p class="font-w600 mb-3">Last month requests: {{ apiUsage.lastMonth }}</p>
                <p class="font-w600 mb-2">Recent Requests</p>

                <table class="table table-striped table-borderless mb-5">
                  <tbody>
                    <tr v-for="request in apiUsage.recentRequests" :key="request._id">
                      <th>{{ request.createdAt | date('Do MMM YYYY HH:mm:ss') }}</th>
                      <td>{{ request.message.replace('/2.0', '') }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ConfirmModal
      :open="modals.confirm"
      title="Generate New API Key"
      text="You are about to generate a new API key. This will <strong>invalidate</strong> your existing API key and you'll need to ensure any applications using the old key are updated with the new key."
      @close="modals.confirm = false"
      @submit="onClickSetupKey"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

import ConfirmModal from '@/components/ConfirmModal';
import FormGroup from '@/components/FormGroup';
import FormItem from '@/components/FormItem';

export default {
  name: 'Profile',
  components: {
    ConfirmModal,
    FormGroup,
    FormItem
  },
  data() {
    return {
      form: {
        name: this.$auth.user.name,
        password: '',
        password2: ''
      },
      loading: {
        usage: false,
        newKey: false,
        getKey: false
      },
      modals: {
        confirm: false
      },
      apiUsage: {
        currentMonth: 0,
        lastMonth: 0,
        recentRequests: [],
        retrieved: false
      },
      apiKey: '',
      user: ''
    };
  },
  computed: {
    ...mapGetters({
      errorAction: 'user/errorAction',
      validationErrors: 'user/validationErrors'
    })
  },
  async mounted() {
    this.user = await this.getUser();
  },
  methods: {
    ...mapActions({
      getUser: 'user/me',
      updateUser: 'user/update',
      getApiUsage: 'user/getApiUsage',
      getApiKey: 'user/getApiKey',
      setupApiAccess: 'user/setupApiAccess'
    }),
    async onSubmit() {
      const response = await this.updateUser({ id: this.user.id, data: this.form });

      if (response?.userSub) {
        this.$toasted.success(this.$t('PROFILE_UPDATED_SUCCESSFULLY'));
      } else {
        this.$toasted.error(this.$t('PROFILE_UPDATED_ERROR'));
      }
    },
    async onClickViewUsage() {
      this.loading.usage = true;
      const response = await this.getApiUsage();

      this.apiUsage = {
        ...response,
        retrieved: true
      };

      this.loading.usage = false;
    },
    async onClickSetupKey() {
      this.loading.newKey = true;
      const response = await this.setupApiAccess();
      this.apiKey = response.apiKey;
      this.loading.newKey = false;

      this.user = await this.getUser();
    },
    async onClickGetKey() {
      if (this.apiKey) {
        this.apiKey = '';
      } else {
        this.loading.getKey = true;
        const response = await this.getApiKey();
        this.apiKey = response.apiKey;
        this.loading.getKey = false;
      }
    }
  }
};
</script>
